<template>
    <div class="add_update_account">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="标题:" prop="title">
                        <el-input v-model="diaform.title" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="通知公告内容:" prop="content">
                        <el-input v-model="diaform.content" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="发布范围:" prop="orgs" v-if="dialogVisible">
                        <el-tree
                            :props="props"
                            show-checkbox
                            node-key="num"
                            ref="orgTree"
                            :default-checked-keys="orgdefaultChecked"
                            :default-expanded-keys="orgdefaultOpen"
                            lazy
                            :check-strictly="true"
                            render-after-expand
                            :load="loadNode"
                            :render-content="renderTreeNode"
                            @check-change="handleOrgCheckChange"
                        ></el-tree>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 树默认展开
            orgdefaultChecked: [],
            orgdefaultOpen: [],

            type: '',
            props: {
                children: 'children',
            },
            title: '',
            diaform: {
                title: '',
                content: '',
                orgs: '',
                orgNames: '',
                num: '',
                path: '',
            },
            rules: {
                title: [{
                    required: true,
                    message: '请输入标题',
                    trigger: 'blur'
                }],
                orgs: [{
                    required: true,
                    message: '请选择发布范围',
                    trigger: 'change'
                }],
            },
            dialogVisible: false,
            formLabelWidth: '100px'
        }
    },
    activated() {
    },
    watch: {
        type: function(val) {
            let t = ''
            switch (val) {
                case 'add':
                    t = '新增通知公告'
                    break
                case 'update':
                    t = '修改通知公告'
                    break
                default:
                    t = '新增通知公告'
            }
            this.title = t
        },
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
                this.orgdefaultOpen = []
                this.orgdefaultChecked = []
                this.diaform.orgNames = ''
                this.diaform.num = ''
                this.diaform.path = ''
            }
        }
    },
    methods: {
        // 数据权限
        loadNode(node, resolve) {
            let id = "";
            if (node.data && node.data.type === 0) {
                id = node.data.data.orgId;
            }
            this.$http
                .post(
                    "/sys/org/queryOrgTree",
                    {
                        orgId: id,
                        entryP: 0
                    },
                    { isRequestParam: false }
                )
                .then(res => {
                    const childNodes = res.detail[0].children;
                    childNodes.forEach(v => {
                        v.num = v.data.num;
                    });
                    resolve(childNodes);
                });
        },
        renderTreeNode(h, { data }) {
            const { data: val } = data;
            return <span>{val.orgNa}</span>;
        },
        init(option, type) {
            this.type = type ? type : 'add'
            this.dialogVisible = true
            if (option) {
                this.$nextTick(() => {
                    this.diaform = {
                        ...JSON.parse(JSON.stringify(option)),
                        orgNames:option.orgNa,
                    }
                    this.orgdefaultChecked = this.diaform.num.split(',').map(item=>Number(item))
                    let openKeys = new Set()
                    this.diaform.path.split(',').forEach(item=>{
                        const list = item.split("/")
                        list.forEach(v => {
                            if (v && !isNaN(v) && !this.orgdefaultChecked.includes(Number(v))) {
                                openKeys.add(Number(v));
                            }
                        });
                    })
                    this.orgdefaultOpen = [...openKeys]
                })
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        // 数据权限树选中
        handleOrgCheckChange() {
            let nodes = this.$refs.orgTree.getCheckedNodes()
            this.diaform.orgs = nodes.map(item=>item.data.orgId).join(',')
            this.diaform.orgNames = nodes.map(item=>item.data.orgNa).join(',')
            this.diaform.num = nodes.map(item=>item.data.num).join(',')
            this.diaform.path = nodes.map(item=>item.data.path).join(',')
        },
        save() {
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    let url = '/sysNotice/'
                    if (this.type == 'add') {
                        url = url + 'addNotice'
                    } else {
                        url = url + 'updateNotice'
                    }
                    let option = JSON.parse(JSON.stringify(this.diaform))
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}

</script>
